//
// Table
//
$table-header-font-family:          $headings-font-family !important;
$table-header-border-top:           2px solid $color-gray-5 !important;
$table-header-border-bottom:        2px solid $color-gray-5 !important;

$table-item-border :                none !important;

%tr{
	height: auto!important;
}
%td{
	width: auto!important;
	height: auto!important;
	padding: 0.5rem 0.5rem !important;
	text-align: center;
	line-height: inherit;
	color: $font-color-base;
	@extend .j-center;
	@extend .center;
}

@mixin striped{
	.table__item:nth-of-type(even), tbody tr:nth-of-type(even){
		background-color: #F2F2F2;
	}
}
@mixin hovered{
	.table__item, tbody tr{
		&:hover{
			background-color: #BAE3F2;
		}
	}
	tbody tr td[rowspan]{
		background-color: #FFF;
	}
}

.object__table{
	.table, table{
		border-collapse: collapse!important;
		border: 2px solid #DCDCDC!important;
		td{
			border: 2px solid #DCDCDC!important;
		}
	}
}


.table, table{
	border-collapse: collapse!important;
	padding-bottom: 1rem;
	padding-top: 1rem;
	border-top: $table-header-border-top;
	width: 100%;
	margin-top: 1rem;

	@include striped;
	@include hovered;

	&__caption, caption{
		font-size: 1.25em;
		font-family: $table-header-font-family;
		text-align: center;
		justify-content: center;
		color: $font-color-base;
		padding: 6px 0 12px 0;
	}
	&__header, thead tr{
		@extend %tr;
		font-family: $table-header-font-family;
		border-bottom: $table-header-border-bottom;
		background-color: $color-bg;
		> div, td{
			@extend %td;
			&:first-child{
				justify-content: flex-start;
			}
		}
	}
	&__item, tbody tr{
		@extend %tr;
		// font-family: $OpenSansSemiBold;
		font-size: 1em;
		border-top: $table-item-border;
		align-items: center;
		align-content: center;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		&:last-child{
			border-bottom: $table-item-border;
		}
		> div, td{
			@extend %td;
			padding: 0.75rem 0.5rem !important;
			&:first-child{
				justify-content: flex-start;
			}

			.fa{
				font-size: 0.9em;
				margin-top: 1px;
			}
			span{
				margin-top: 1px;
			}
		}
	}

	&_striped{
		@include striped;
	}
	&_hovered{
		@include hovered;
	}

}

.table.table_start{
	.table__item{
		text-align: left;
		> div, td{
			@extend .j-start;
			@extend .start;
			text-align: left;
		}
	}
}