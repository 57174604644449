.benefits-cover-img{
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: contain;
}

$gradient: linear-gradient(90deg, #393185, #00A0E3);

.industries{
    background: $gradient;

    .h1{
        color: $color-clouds
    }
    &__list{
        color: $color-clouds;
        @include custom-markers('\f068', $color-clouds);
    }
}

