.tabs{
    padding-top: 1rem;
    padding-bottom: 1rem;
    &__nav{
        // padding-bottom: 1rem;
        // border-top: 2px solid $color-gray-6;
        // border-bottom: 2px solid $color-gray-6;

        &__item{
            padding-top: 1.08rem;
            padding-bottom: 1rem;
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            margin-bottom: 1rem;

            @include media-breakpoint-only(xs){
                padding: 0.75rem 0.5rem;
            }

            font-family: $headings-font-family;
            color: $color-gray-3;

            border-radius: 5px;
            border: 1px solid #E9E9E9;

            @include hover-focus-active{
                cursor: pointer;
                border: 1px solid $color-prime;
                // background-color: $color-gray-6;
            }

            &.active{
                color: $color-gray-1;
                cursor: default;
                background-color: transparent;
                @extend %hover-effect-shadow;
            }
            &_img{
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;
                min-height: 52px;
                @include media-breakpoint-only(sm){
                    min-height: 32px;
                }
            }
        }
    }
    &__tab{
        display: none;

        padding-top: 1rem;
        padding-bottom: 1.5rem;
        
        &.active{
            display: flex;
        }
    }
}