// popup-wrapper >
//     button +
//     popup

.popup{
    position: absolute;
    @extend %hover-effect-shadow2;

    border-radius: 0px;
    padding: .3rem 5 .3rem 5;

    &_show{
        visibility: visible;
        opacity: 1;
        z-index: $popup-z-index;
    }
    &_hide{
        visibility: hidden;
        opacity: 0;
        z-index: -100;
    }
}

//default

.popup{
    &_partner{
        transition-property: opacity;
        background-color: #f3f3f3;

        padding-top: .7rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;

        top: 0;
        left: 0;

        box-sizing: border-box;
        width: 100%;
        @include media-breakpoint-only(md){
            width: map-get($container-max-widths, 'sm');
        }
        @include media-breakpoint-up(md){
            width: map-get($container-max-widths, 'md');
        }
        &__arrow{
            transition: none;
            position: absolute;
            top: -8px;
            left: 30px;
            margin-right: 5px;
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 8px solid $color-base;
        }
        &__close{
            position: absolute;
            top: .5rem;
            right: .5rem;
            padding: 1rem;
            border: 1px solid transparent;
            border-radius: 3px;
            @include hover-focus-active{
                cursor: pointer;
                border-color: #ddd;
                background-color: #eee;
            }
        }
    }
}

// Catalog
.popup{
    &__catalog{
        &__wrapper{
            // position: static;
            background-color: #fff;
            // margin-right: -4px;
            a{
                // margin-right: -4px;
            }
        }
        &-nav {
            // top: 50px;
            background-color: $color-gray-3;

            padding: 4 0;
            width: 232px;
        }
    }
}

// .popup_drop-menu {
//     width: map-get($container-max-widths, xl)-50px;
//     top: 64px;

//     .popup {
//         &__body {
//             &__columns {
//                 width: 20% !important;
//             }
//         }
//     }

// }