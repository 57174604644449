$fontPath: "../assets/fonts/";


@font-face {
    font-family: 'OpenSans';
    src: url($fontPath+'Open_Sans/OpenSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSansItalic';
    src: url($fontPath+'Open_Sans/OpenSans-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSansSemiBold';
    src: url($fontPath+'Open_Sans/OpenSans-Semibold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSansSemiBoldItalic';
    src: url($fontPath+'Open_Sans/OpenSans-SemiBoldItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSansBold';
    src: url($fontPath+'Open_Sans/OpenSans-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSansBoldItalic';
    src: url($fontPath+'Open_Sans/OpenSans-BoldItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

//Rubik
@font-face {
	font-family: 'Rubik';
	src: url($fontPath+'Rubik/static/Rubik-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style:normal;
}
@font-face {
	font-family: 'RubikMedium';
	src: url($fontPath+'Rubik/static/Rubik-Medium.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'RubikSemiBold';
	src: url($fontPath+'Rubik/static/Rubik-SemiBold.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'RubikBold';
	src: url($fontPath+'Rubik/static/Rubik-Bold.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'RubikBoldItalic';
	src: url($fontPath+'Rubik/static/Rubik-BoldItalic.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

//PT Mono

@font-face {
	font-family: 'PTMono';
	src: url($fontPath+'PT_Mono/pt_mono.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
