%input{
    padding: 0.65rem 0.875rem;
    margin-bottom: .5rem;

    border: 1px solid #e2e2e2;
    border-radius: 5px;
    background-color: $color-clouds;

    font-family: $ff-normal;
    color: $color-base;

    &:focus{
        outline: none;
        border-color: transparentize($color-prime, 0.5);
        background-color: #fff;
    }
    &.error{
        border-color: $color-error;
    }
}

form, .form{
    padding-top: 0.3em;
    padding-bottom: 20px;
    margin-bottom: 0;
    margin-top: 0;
	>p {
	    color: $color-base;
	    text-align: center;
    }
    &__title{
        text-transform: none;
        font-size: 1.75rem;
    }
}

fieldset, .fieldset, .group {
	border: none;
    padding: 0.35em 0.75em 0.35em;
	margin: 0;
	// padding-top: 0;
    // padding-bottom: 5px;
}
.group_boolean, .fieldset_boolean {
    margin-bottom: 1.125em !important;
}

legend {
    margin-bottom: .5em;

    font-family: $headings-font-family;
    font-size: $h3-font-size;
    line-height: 1.4em;
    color: $headings-color;
    text-transform: none;

    &.small {
        font-family: $ff-medium;
        font-size: 1em;
        color: $headings-color;
    }
}

label {
    @extend %flex;
    flex-grow: 0;

    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    // margin-bottom: .3em;

    font-family: $headings-font-family;
    font-size: 1em;
    color: $headings-color;

}
// .fields-column, .form-column{
//     .fields{
//         padding-top: 0rem;
        
//     }
//     .field{
//         flex-direction: column;
//         align-items: stretch;
//         align-content: stretch;
//     }
//     label{
//         width: 100%;
//         margin-top: .5rem;
//         margin-bottom: .5rem;
//     }
// }
.form_steps{
    .fields{
        @include column();
    }
}
.form-item{
    padding-top: 0.5rem;
    padding-bottom: 1rem!important;
}

%field-shadow{
    box-shadow: 0px 0px 16px rgba(0,0,0,0.1);
}

.field {
    position: relative;
    flex-direction: column;
    align-items: stretch;
    align-content: stretch;

    // padding-bottom: 0.5rem;
    // @extend .col-24;

    &__item{
        @extend %flex;
        flex-grow: 1;
        max-width: 100%;
        @extend %input;
        // padding: 10px $grid-gutter/2 8px $grid-gutter/2;
        
        // background-color: $color-gray-6;
        // border: 1px solid #E8E8E8;
        // border-bottom: 2px solid $color-gray-2;
        // border-radius: 0;
        
        // font-family: $ff-normal;
        // margin-bottom: .8em;
        // color: $color-gray-4;

        option:disabled{
            cursor: not-allowed;
            font-family: $ff-h-bold;
            color: $color-gray-6;
        }
    }
 
    .select{
        position: relative;

        select{ 
            appearance: none;
            position: relative;

            color: $color-gray-6;

            option{
                color: $color-gray-6;
                background-color: #fff;
                &:first-child{
                    color: $color-gray-4;
                }
            }
        }
        &__arrow{
            position: absolute;
            right: 13px;
            top: 11px;

            color: $color-gray-2;
        }

        @include hover-focus-active{
            select{
                background-color: $color-gray-1;
            }
            &__arrow {
                color: $color-gray-3;
            }
        }
    }

    &__number {
        // align-items: stretch;
        flex-direction: row;
        align-items: stretch;

        input {
            width: 3.75rem;
            margin-left: 4px;
            margin-right: 4px;
            text-align: center;

            -moz-appearance: textfield;
            appearance: textfield;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                display: none;
                appearance: none;
                margin: 0;
            }
        }

        button,
        .btn {
            padding-left: .9rem;
            padding-right: .9rem;

            svg {
                font-size: 12px;
                color: $color-gray-4;
            }
        }
    }

}

.field_hightlight{
    .field__item{
        border-width: 2px;
        border-color: #707070;
    }
}
.field_light{
    label{
        color: #999999;
    }
    .field__item{
        color: #999999;
    }
}
// Number

input[type="number"] {
    // width: 3.75rem;
    // margin-left: 4px;
    // margin-right: 4px;
    // text-align: center;

    -moz-appearance: textfield;
    appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        display: none;
        appearance: none;
        margin: 0;
    }
}

// Boolean

.field.boolean{
    flex-direction: column;
}

input[type="checkbox"], input[type="radio"] {
    display: none;
}

input[type="checkbox"]+label, input[type="radio"]+label {
    $root: &;
    flex-grow: 1;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    padding-top: .75rem;
    padding-bottom: .75rem;
    padding-right: 0.5rem;
    // margin-bottom: .7rem;
    // margin-bottom: .875em;
    // padding-left: 1.125em;

    font-family: $ff-h-bold;
    font-size: 1em;
    color: $font-color-base;

    cursor: pointer;
    user-select: none;

    align-items: center;

    &:hover{
        #{$root}:before{
            background-color: $color-gray-4;
        }
    }
}

// input[type="checkbox"] + label:last-child { margin-bottom: 0; }

input[type="checkbox"]+label:before, input[type="radio"]+label:before {
    // @extend %flex;
    content: '';
    flex-shrink: 0;

    text-align: center;
    text-align: center;
    justify-content: center;
    font-family: $ff-fa;
    font-weight: 900;

    font-size: 0.875em;
    line-height: 1rem;
    width: 1rem;
    height: 1rem;
    border-radius: 3px;
    
    margin-right: .75rem;
    background-color: #fff;

    border: 2px solid $color-border-3;

    transition: all .3;
}
input[type="radio"]+label:before{
    border-radius: 1rem;
    font-size: 0.55em;
}
.error input[type="checkbox"]+label:before{
    border: 1px solid $color-danger;
}

input[type="checkbox"]:checked+label:before, input[type="radio"]:checked+label:before {
    // content: '\f111';
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    content: "\f00c";
    color: $color-prime;
    background-color: #fff;
}
input[type="radio"]:checked+label:before {
    content: '\f111';
}




table, .table{
    .field{
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: 0;
        &__number{
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}

.form__privacy-polic-note{
    padding-top: 1.5rem;
    font-family: $ff-italic;
    a{
        font-family: $ff-italic;
    }
}

.form__error-text, .form__error-bot-text{
    color: $color-danger;
    padding-bottom: 1rem;
    font-family: $ff-h-bold-i;
}

// Состояния
.field {
	&__error-text {
	    display: none;
	    font-family: $ff-h-bold-i;
        color: $color-danger;
        // margin-top: 5px;
        padding-bottom: 1rem;
	}
    &.error {
        .field {
            &__item {
                border-color: transparentize($color-danger, 0.5);
            }
            &__error-text{
                display: flex;
            }
        }
    }
    &.successful{
        .field {
            &__item {
                border-color: transparentize($color-success, 0.5);
            }
        }
    }
}


.req-star {
    position: absolute;
    top: 10px;
    right: 0px;
    color: $color-asbestos;
}


.form_search {
    padding: 0 0;

    .field {
        padding-bottom: 0;

        &__item {
            @extend %input;
            @extend %field-shadow;
            margin-bottom: 0;

            border-top-right-radius: 0;
            border-top-left-radius: 0;

            .fas{
                padding-right: 0;
            }
        }
    }

    &__icon {
        position: absolute;
        top: 15px;
        right: 12px;

        color: $color-gray-4
    }
}
.m-form_search{
    padding-top: 0;
    padding-bottom: 0;
    height: 70px;

    .field{
        padding-bottom: 0;
        &__item{
            background-color: rgba($color: #ffffff, $alpha: 0.2);
            border-color: rgba($color: #ffffff, $alpha: 0.2);
            margin-bottom: 0;
            color: $color-clouds;
        }
    }
    .form_search__icon{
        color: $color-clouds;
    }
}

.form-wrapper{
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(10px);
    padding: 2rem 2.5rem;
    border: 1px solid $color-clouds;
}