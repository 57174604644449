%header-item{
    @extend %flex;
    @extend .row;
    @extend .center;
    @extend .no-wrap;
    color: $color-base;
    .fas{
        @include base-text-font-size(1.125);
    }
    
}
%header-link{
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border: 1px solid transparent;
    
    @include hover-focus-active{
        color: $color-prime;
        text-decoration: none;
        
        border-color: transparent;
        // background-color: rgba(#333, 0.03);

        border-radius: 3px;
        cursor: pointer;

        .header__callback{
            &__title{
                text-decoration: none;
            }
            &__title, &__desc, .icon{
                color: $color-base;
            }
        }
    }
    @include media-breakpoint-up(md){

    }
}

.header{
    background-color: #fff;
    color: $color-base;
    &::after{
        content: '';
        display:inline-block;
        height: 8px;
        width: 100%;
        background: linear-gradient(90deg, #393185, #00A0E3);
    }

    &.with-banner{
        &::after{
            display: none !important;
        }
    }

    &__user,
    &__search-icon,
    &__search-icon-close,
    &__mob-menu-btn{
        @extend %header-item;
        @extend %header-link;
    }

    &__logo{
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-right: 0;
        padding-left: 0;
        img{            
            width: auto;
            height: 42px !important;
            @include media-breakpoint-up(md){
                height: 56px !important;
            }
            @include media-breakpoint-up(lg){
                height: 78px !important;
            }
            @include media-breakpoint-up(xl){
                height: 78px !important;
            }
        }
        a{
            font-family: $ff-h-bold;
            color: $color-base;
            text-decoration: none;
        }
    }
    &__callback{
        flex-direction: column;
        @extend .j-center;

        font-family: $ff-h-medium;
        color: $color-concrete;

        text-decoration: underline;
        padding-top: 2px;
    }
    &__contacts{
        @include media-breakpoint-up(xl){
            // padding-left: 3rem;
        }
    }
    &__phone{
        color: $color-base;
        font-family: $ff-h-medium;
        font-size: 1.2rem;
        line-height: 1em;
        text-decoration: none;

        @include media-breakpoint-up(md){
            font-size: 1.4rem;
        }

        @include hover-focus-active {
            color: $color-gray-3;
        }
    }

    &__search-form{
        padding-left: 90px;
    }
    &__search-form,
    &__search-icon-close {
        visibility: hidden;
        opacity: 0;
        display: none;
    }
    &__menu-trigger{
        &__title{
            padding-right: $grid-gutter/2;

            font-family: $ff-h-bold;
            // font-size: 18px;
        }
        a{
            @extend %header-item;
            @extend %header-link;
            text-decoration: none;

            
            // @include media-breakpoint-up(sm){ width: 70px;}
            // @include media-breakpoint-up(lg){ width: 80px;}

            .fa-times{ display: none }
            .fa-bars{ display: block }
            &:active, &.active{
                .fa-times{ display: block }
                .fa-bars{ display: none }
            }
        }
    }
}

.header-mobile{
    height: 60px;
    background-color: #fff;

    color: #fff;
    font-size: 14px;
    border-bottom: 8px solid $color-prime;
}