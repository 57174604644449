@mixin teaser{

    background-color: transparent;

    margin-top: 1rem;
    margin-bottom: 1rem;

    // padding: 0.5rem 0.5rem 1rem;
    // @include media-breakpoint-up(sm){
    //     padding: 1.2rem 2rem 1.2rem;
    // }

    &__text{        
        padding-left: $grid-gutter/2;
        padding-right: $grid-gutter/2;
        padding-bottom: 1rem;

        font-family: $OpenSans;
        @include base-text-font-size(1);
        line-height: 1.4em;
    }
    &__title{
        @extend .h2;
        text-transform: unset;
    }
    &__desc{
        font-family: $ff-h-normal;
        line-height: 1.4em;
    }
    &__phone{
        font-size: 1.7rem;
        line-height: 1.6em;
        font-family: $ff-h-bold;
        @include media-breakpoint-only(sm){
            font-size: 1.5rem;
        }
    }
    &__btn{
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
    &__contacts{
        padding-top: 0.5rem;
        padding-bottom: 1rem;
        padding-right: 1rem;

        @include media-breakpoint-up(md){
            padding-bottom: 0.5rem;
        }
        // align-self: flex-end;
        @include media-breakpoint-up(md){
            padding-left: 2rem;
        }
        @include media-breakpoint-up(lg){
            padding-right: 2rem;
        }
        @include media-breakpoint-up(xl){
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            // align-self: center;
        }
    }
}

.teaser{
    @include teaser;
}

.teaser_consult{
    $root: teaser;

    padding-top: 1rem;
    padding-bottom: 1rem;
    @include media-breakpoint-up(sm){
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    @include media-breakpoint-up(md){
        padding: 2rem;
    }
    @include media-breakpoint-up(xl){
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }

    border: none;
    border-radius: 5px;
    background: linear-gradient(90deg, #393185, #00A0E3);

    color: $color-clouds;

    &.wide{
        border-radius: 0;
    }


    .#{$root}__consult-icon{
        font-size: 65px;
        color: $color-clouds;
        padding-right: 1rem;
    }
    .#{$root}__info{
        @include media-breakpoint-up(xxl){
            flex-grow: 1;
            align-items: center;
            align-content: center;
            justify-content: space-between;
        }
    }

    .#{$root}__title{
        color: $color-clouds;
        margin-bottom: 0.2em;
    }
}

.teaser__about{
    @include teaser;

    padding-left: 2rem;
    border-left: 4px solid $color-prime;
    margin-bottom: 2rem;

    &__company{
        font-family: $ff-h-bold;
        font-size: 2rem;
        margin-bottom: 1rem;
    }
}

.teaser__form{
    &-title{
        padding-left: 0;
        h3{
            margin-top: 0.3em;
            margin-bottom: 0.5em;
        }    
        @include media-breakpoint-up(sm){    
            padding-left: 4rem;
            &:before{
                position: absolute;
                top: 17px;
                left: 0rem;
            
                font-family: "Font Awesome 6 Free";
                content: "\f658";
                line-height: 3rem;
                font-weight: 900;
                font-size: 3rem;
                text-align: center;
                color: $color-prime;
                transition: all .3;
            }
        }
        @include media-breakpoint-up(md){
            margin-top: 0;
        }
        .subheader{
            margin-bottom: 0.5rem;
        }
    }
}
