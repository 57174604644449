.category-card {
    @include card;

    // @extend %hover-effect-shadow;
    @include media-breakpoint-only(xs){
        padding-left: 0;
        padding-right: 0;
        &__wrapper{
            padding-left: 0;
            padding-right: 0;
        }
    }

    &__img {
        height: 13em;
        margin-top: $grid-gutter/2;
        // padding-bottom: $grid-gutter/2;
    }

    &__name {
        padding-top: 0.5em;
        padding-bottom: 0.5em;

        font-family: $ff-h-bold;
        font-size: 1.125rem;
    }
    @include hover-focus-active{
        border-color: transparentize($color-prime, 0.5);
    }
}