.benefits{
    background-color: $color-bg;
    ul{ @include custom-markers('\f068', $color-prime) }

    &__img{
        position: absolute;
        height: 100%;
        width: 50%;
        right: 0;
    }
}

.main-page{
    h2.h2{
        font-size: $h1-font-size;
    }

}
.examples{
    &__nav{
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        &__item{
            font-family: $ff-h-bold;
            color: $color-prime;
            &.active{
                text-decoration: none;
                color: $font-color-base;
                &::before{
                    content: '\f0da';
                    font-family: "Font Awesome 6 Free";
                    font-weight: 900;
                    color: $color-prime;
                    padding-right: 1rem;
                }
            }
        }
    }
    &__tabs{
        &__tab{
            display: none;
            &.active{
                display: flex;
            }
        }
    }
    &__info{
        padding-left: 2rem;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        border-top: 2px solid #DBDBDB;
        border-bottom: 2px solid #DBDBDB;
        i{
            color: $color-prime;
            font-size: 3.5rem;
            padding-right: 1rem;
        }
    }
    &__img{
        min-height: 15rem;
        border-radius: 5px;
        margin-bottom: 1rem;
    }
}

.how-we-work{
    &__item{
        &__pos{
            span{
                width: 4rem;
                height: 4rem;
                border-radius: 2rem;
                font-family: $ff-h-bold;
                font-size: 2rem;
                line-height: 4rem;
                text-align: center;
                color: #fff;
                background-color: $color-prime;
            }
        }
        &__title{
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
            font-family: $ff-h-bold;
            font-size: 1.111rem;
        }
        &__desc{

        }
    }
}