%modal_pddings {
    padding-left: 0;
    padding-right: 0;
    @include media-breakpoint-up(sm){
        padding-left: 2rem;
        padding-right: 2rem;
    }
    @include media-breakpoint-up(md){
        padding-left: 3rem;
        padding-right: 3rem;
    }
}

.modal-form{
    &.wrapper{
        border-radius: 10px;
        padding: 1.7rem 0;
    }
    &__title{
        h2{
            @extend %modal_pddings;
            // border-left: 8px solid $color-prime;
        }
        p{
            @extend %modal_pddings;
        }
    }
    &__form{
        @extend %modal_pddings;
        form{
            padding-top: 0!important;
        }
    }
}

.modal-form-size{
    max-width: 100%;
    @include media-breakpoint-up(sm){ max-width: 83.33333%!important;}
    @include media-breakpoint-up(md){ max-width: 66.66667%!important;}
    @include media-breakpoint-up(lg){ max-width: 50%!important;}
    @include media-breakpoint-up(xl){ max-width: 41.66667%!important;}
}