.list-item-card {
    @include card;
    &__wrapper{
        margin-bottom: 0;
    }
    &__name {
        padding-top: 0.5em;
        padding-bottom: 0.5em;

        font-family: $ff-h-bold;
        @include base-text-font-size(1.125);
    }
    &__icon{
        font-size: 4rem;

        background: linear-gradient(45deg, #393185, #00A0E3);
        color: #00A0E3;
        -webkit-background-clip: text;
        background-clip: text;

        padding-bottom: 1rem;

    }

    @include hover-focus-active {
        // border-bottom: 2px solid $color-gray-4;
        cursor: default;
        box-shadow: none;
    }
}