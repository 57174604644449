$baseFontSize: 16;
@function rem($pixels, $context: $baseFontSize) {
    @if (unitless($pixels)) {
        $pixels: $pixels * 1px;
    }
    @if (unitless($context)) {
        $context: $context * 1px;
    }
    @return $pixels / $context * 1rem;
}

@mixin base-text-font-size($extra){
    font-size: $font-size-base * $extra;
    @include media-breakpoint-up(xl){
      font-size: $font-size-base * 1.125 * $extra;
    }
  }

@mixin hidet-desc {
height: 5em;
overflow: hidden;
&:after {
    content: "";
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 4em;
    background: linear-gradient(to bottom, #F9F9F900, #fff 100%);
    pointer-events: none;
}
}