* {
    transition: 0.3s ease;
}
html{
    font-family: sans-serif; // 2
    line-height: $line-height-base; // 3
    -webkit-text-size-adjust: 100%; // 4
    -ms-text-size-adjust: 100%; // 4
    -ms-overflow-style: scrollbar; // 5
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); // 6

    // font-size: $font-size-sm;
    // min-height: 100%;

    font-size: $font-size-base;
}
body{
    margin: 0; // 1
    font-family: $font-family-base;
    // font-size: $font-size-base;
    font-weight: normal;
    color: $font-color-base;
    text-align: left; // 3
    min-height: 100vh;
    
    // background-color: $body-bg; // 2
}
figure{
    margin: 0;
}
#root>div{
    min-height: 100vh;
    flex-shrink: 1;
    // align-items: stretch;
}
.loading{
    @extend %flex;
    width: 100%;
    height: 100%;
    background-color: $color-bg-2;
    justify-content: center;
    align-items: center;
    span{
        // color: $color-bg-2;
        font-size: rem(32);
    }
}
.main {
    // padding-top: 1rem;
    // padding-bottom: 2rem;
    min-height: 75vh;
}
.info-page{
    padding-bottom: 2rem;
}
.system-page{
    padding-top: 40px;
    padding-bottom: 4rem;
}

.fancybox-content{
    background-color: #fff!important;
}
@include media-breakpoint-only(xs){
    .fancybox-content{
        padding: 1rem!important;
    }
}